// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-leit-js": () => import("./../../../src/pages/leit.js" /* webpackChunkName: "component---src-pages-leit-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-council-people-template-js": () => import("./../../../src/templates/councilPeopleTemplate.js" /* webpackChunkName: "component---src-templates-council-people-template-js" */),
  "component---src-templates-discount-template-js": () => import("./../../../src/templates/discountTemplate.js" /* webpackChunkName: "component---src-templates-discount-template-js" */),
  "component---src-templates-docs-link-template-js": () => import("./../../../src/templates/docsLinkTemplate.js" /* webpackChunkName: "component---src-templates-docs-link-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../../../src/templates/faqTemplate.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-front-page-template-js": () => import("./../../../src/templates/frontPageTemplate.js" /* webpackChunkName: "component---src-templates-front-page-template-js" */),
  "component---src-templates-history-template-js": () => import("./../../../src/templates/historyTemplate.js" /* webpackChunkName: "component---src-templates-history-template-js" */),
  "component---src-templates-info-page-repater-js": () => import("./../../../src/templates/InfoPageRepater.js" /* webpackChunkName: "component---src-templates-info-page-repater-js" */),
  "component---src-templates-mental-health-template-js": () => import("./../../../src/templates/mentalHealthTemplate.js" /* webpackChunkName: "component---src-templates-mental-health-template-js" */),
  "component---src-templates-news-archive-template-js": () => import("./../../../src/templates/newsArchiveTemplate.js" /* webpackChunkName: "component---src-templates-news-archive-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-organisation-template-js": () => import("./../../../src/templates/organisationTemplate.js" /* webpackChunkName: "component---src-templates-organisation-template-js" */),
  "component---src-templates-simple-page-template-js": () => import("./../../../src/templates/simplePageTemplate.js" /* webpackChunkName: "component---src-templates-simple-page-template-js" */),
  "component---src-templates-start-org-template-js": () => import("./../../../src/templates/startOrgTemplate.js" /* webpackChunkName: "component---src-templates-start-org-template-js" */),
  "component---src-templates-volunteering-template-js": () => import("./../../../src/templates/volunteeringTemplate.js" /* webpackChunkName: "component---src-templates-volunteering-template-js" */)
}

